import * as types from "./actionTypes";
import Immutable from "seamless-immutable";
import _ from "lodash";

// import reducers from 'xx/redux-store/reducers' // default reducers for logging out

const resetPasswordDefaults = {
  isRequestingRecovery: false,
  recoveryUsername: null,
  recoveryRequestError: "",
  recoveryCodeDestination: null,
  isSavingNewPassword: false,
  resetPasswordError: "",
  recoveryStep: 0,
};

const registerAccountDefaults = {
  registrationStep: 0,

  isRegistering: false,
  registrationError: "",
  registrationUsername: null,

  isVerifyingRegistration: false,
  verifyRegistrationError: "",

  resendCodeUsername: null,
};

const initialState = Immutable({
  loggedIn: false,
  loggingIn: false,
  loginError: "",

  loggingOut: false,
  logoutError: "",

  ...resetPasswordDefaults,
  ...registerAccountDefaults,

  isLoadingUsageData: false,
  loadingUsageDataError: "",
  usageData: {},

  isLoadingAlias: false,
  loadingAliasError: "",
  alias: null,

  isLoadingAccount: false,
  loadingAccountError: "",
  account: {},

  isLoadingStripe: false,
  loadingStripeError: "",
  stripeCard: null,

  isLoadingStripePortal: false,
  loadingStripePortalError: "",
  stripePortal: null,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGIN_RESET:
      return state.merge({
        loggingIn: false,
        loginError: "",
        resendCodeUsername: null,
      });

    case types.LOGIN_REQUEST:
      return state.merge({
        loggingIn: true,
        loggedIn: false,
        user: null,
        loginError: "",
      });

    case types.LOGIN_ERROR:
      return state.merge({
        loginError: action.errorMessage,
        loggingIn: false,
        loggedIn: false,
        resendCodeUsername: action.notconfirmed,
        user: null,
      });

    case types.LOGIN_SUCCESS:
      return state.merge({
        loggingIn: false,
        loggedIn: true,
        user: action.user,
        loginError: "",
      });

    case types.LOGOUT_REQUEST:
      return state.merge({
        loggingOut: true,
      });

    case types.LOGOUT_ERROR:
      return state.merge({
        logoutError: action.errorMessage,
        loggingOut: false,
      });

    case types.LOGOUT_SUCCESS:
      return state.merge({
        loggingOut: false,
        loggedIn: false,
        user: null,
      });

    case types.REGISTER_REQUEST:
      return state.merge({
        isRegistering: true,
        registrationError: "",
        registrationUsername: action.username,
      });

    case types.REGISTER_ERROR:
      return state.merge({
        isRegistering: false,
        registrationError: action.errorMessage,
      });

    case types.REGISTER_SUCCESS:
      return state.merge({
        isRegistering: false,
        registrationError: "",
        registrationStep: 1,
      });

    case types.RECOVERY_REQUEST:
      return state.merge({
        isRequestingRecovery: true,
        recoveryRequestError: "",
        codeDeliveryDetails: null,
      });

    case types.RECOVERY_REQUEST_SUCCESS:
      return state.merge({
        isRequestingRecovery: false,
        codeDeliveryDetails: action.codeDeliveryDetails,
        recoveryStep: 1,
        recoveryUsername: action.username,
      });

    case types.RECOVERY_REQUEST_ERROR:
      return state.merge({
        isRequestingRecovery: false,
        recoveryRequestError: action.errorMessage,
      });

    case types.RESET_PASSWORD_REQUEST:
      return state.merge({
        isSavingNewPassword: true,
        resetPasswordError: "",
      });

    case types.RESET_PASSWORD_ERROR:
      return state.merge({
        isSavingNewPassword: false,
        resetPasswordError: action.errorMessage,
      });

    case types.RESET_PASSWORD_SUCCESS:
      return state.merge({
        isSavingNewPassword: false,
        resetPasswordError: "",
        recoveryStep: 2,
      });

    case types.RESET_PASSWORD_RESET:
      return state.merge(resetPasswordDefaults);

    case types.REGISTER_RESET:
      return state.merge(registerAccountDefaults);

    case types.REGISTER_VERIFY_REQUEST:
      return state.merge({
        isVerifyingRegistration: true,
        verifyRegistrationError: "",
      });

    case types.REGISTER_VERIFY_SUCCESS:
      return state.merge({
        isVerifyingRegistration: false,
        registrationStep: 2,
      });

    case types.REGISTER_VERIFY_ERROR:
      return state.merge({
        isVerifyingRegistration: false,
        verifyRegistrationError: action.errorMessage,
      });

    case types.LOAD_USAGE_DATA_REQUEST:
      return state.merge({
        isLoadingUsageData: true,
        loadingUsageDataError: "",
      });

    case types.LOAD_USAGE_DATA_SUCCESS:
      return state.merge({
        isLoadingUsageData: false,
        loadingUsageDataError: "",
        usageData: action.usageData,
      });

    case types.LOAD_USAGE_DATA_ERROR:
      return state.merge({
        isLoadingUsageData: false,
        loadingUsageDataError: action.errorMessage,
      });

    case types.LOAD_ACCOUNT_REQUEST:
      return state.merge({
        isLoadingAccount: true,
        loadingAccountError: "",
      });

    case types.LOAD_ACCOUNT_SUCCESS:
      return state.merge({
        isLoadingAccount: false,
        loadingAccountError: "",
        account: action.account,
      });

    case types.LOAD_ACCOUNT_FAILURE:
      return state.merge({
        isLoadingAccount: false,
        loadingAccountError: action.errorMessage,
      });

    case types.LOAD_STRIPE_REQUEST:
      return state.merge({
        isLoadingStripe: true,
        loadingStripeError: "",
      });

    case types.LOAD_STRIPE_SUCCESS:
      return state.merge({
        isLoadingStripe: false,
        loadingStripeError: "",
        stripeCard: action.stripeCard,
      });

    case types.LOAD_STRIPE_FAILURE:
      return state.merge({
        isLoadingStripe: false,
        loadingStripeError: action.errorMessage,
      });

    case types.LOAD_STRIPE_PORTAL_REQUEST:
      return state.merge({
        isLoadingStripePortal: true,
        loadingStripePortalError: "",
      });

    case types.LOAD_STRIPE_PORTAL_SUCCESS:
      return state.merge({
        isLoadingStripePortal: false,
        loadingStripePortalError: "",
        stripePortal: action.stripePortal,
      });

    case types.LOAD_STRIPE_PORTAL_FAILURE:
      return state.merge({
        isLoadingStripePortal: false,
        loadingStripePortalError: action.errorMessage,
      });

    case types.LOAD_INVOICES_REQUEST:
      return state.merge({
        isLoadingInvoices: true,
        invoices: [],
        loadingInvoicesError: "",
      });

    case types.LOAD_INVOICES_SUCCESS:
      return state.merge({
        isLoadingInvoices: false,
        loadingInvoicesError: "",
        invoices: _.get(action, "result.data", []),
      });

    case types.LOAD_INVOICES_FAILURE:
      return state.merge({
        isLoadingInvoices: false,
        loadingInvoicesError: action.errorMessage,
      });

    case types.LOAD_STRIPE_CUSTOMER_REQUEST:
      return state.merge({
        isLoadingStripeCustomer: true,
        stripeCustomer: null,
        loadingStripeCustomerError: "",
      });

    case types.LOAD_STRIPE_CUSTOMER_SUCCESS:
      return state.merge({
        isLoadingStripeCustomer: false,
        loadingStripeCustomerError: "",
        stripeCustomer: _.get(action, "result", null),
      });

    case types.LOAD_STRIPE_CUSTOMER_FAILURE:
      return state.merge({
        isLoadingStripeCustomer: false,
        loadingStripeCustomerError: action.errorMessage,
      });

    case types.LOAD_ALIAS_REQUEST:
      return state.merge({
        isLoadingAlias: true,
        loadingAliasError: "",
      });

    case types.LOAD_ALIAS_SUCCESS:
      return state.merge({
        isLoadingAlias: false,
        loadingAliasError: "",
        alias: action.alias,
      });

    case types.LOAD_ALIAS_FAILURE:
      return state.merge({
        isLoadingAlias: false,
        loadingAliasError: action.errorMessage,
      });

    case types.LOAD_API_KEYS_REQUEST:
      return state.merge({
        isLoadingApiKeys: true,
        ApiKeys: [],
        loadingApiKeysError: "",
      });

    case types.LOAD_API_KEYS_SUCCESS:
      return state.merge({
        isLoadingApiKeys: false,
        loadingApiKeysError: "",
        apiKeys: _.get(action, "result", null),
      });

    case types.LOAD_API_KEYS_FAILURE:
      return state.merge({
        isLoadingApiKeys: false,
        loadingApiKeysError: action.errorMessage,
      });

    case types.GENERATE_API_KEY_REQUEST:
      return state.merge({
        isGeneratingApiKey: true,
        generatingApiKeyError: "",
      });

    case types.GENERATE_API_KEY_SUCCESS:
      let mutableApiKeys = state.apiKeys.asMutable();
      let key = action.result;
      key.new = true;
      mutableApiKeys.push(key);
      return state.merge({
        isGeneratingApiKey: false,
        generatingApiKeyError: "",
        apiKeys: mutableApiKeys,
      });

    case types.GENERATE_API_KEY_FAILURE:
      return state.merge({
        isGeneratingApiKey: false,
        generatingApiKeyError: action.errorMessage,
      });

    default:
      return state;
  }
}

export function userLoggedIn(state) {
  return _.get(state, "user.loggedIn", false);
}

export function userLoggingOut(state) {
  return _.get(state, "user.loggingOut", false);
}

export function userLoggingIn(state) {
  return _.get(state, "user.loggingIn", false);
}

export function userLoginError(state) {
  return _.get(state, "user.loginError", "");
}

export function userLogoutError(state) {
  return _.get(state, "user.logoutError", "");
}

export function isRegistering(state) {
  return _.get(state, "user.isRegistering", false);
}

export function registrationError(state) {
  return _.get(state, "user.registrationError", "");
}

export function getRecoveryStep(state) {
  return _.get(state, "user.recoveryStep", 0);
}

export function getCodeDeliveryDetails(state) {
  return _.get(state, "user.codeDeliveryDetails", null);
}

export function recoveryRequestError(state) {
  return _.get(state, "user.recoveryRequestError", false);
}

export function isRequestingRecovery(state) {
  return _.get(state, "user.isRequestingRecovery", false);
}

export function isSavingNewPassword(state) {
  return _.get(state, "user.isSavingNewPassword", false);
}

export function recoveryUsername(state) {
  return _.get(state, "user.recoveryUsername", null);
}

export function resetPasswordError(state) {
  return _.get(state, "user.resetPasswordError", null);
}

export function getRegistrationStep(state) {
  return _.get(state, "user.registrationStep", 0);
}

export function getRegistrationUsername(state) {
  return _.get(state, "user.registrationUsername", 0);
}

export function isVerifyingRegistration(state) {
  return _.get(state, "user.isVerifyingRegistration", false);
}

export function getVerifyRegistrationError(state) {
  return _.get(state, "user.verifyRegistrationError", "");
}

export function getResendCodeUsername(state) {
  return _.get(state, "user.resendCodeUsername", "");
}

export function isLoadingUsageData(state) {
  return _.get(state, "user.isLoadingUsageData", false);
}

export function loadingUsageDataError(state) {
  return _.get(state, "user.loadingUsageDataError", false);
}

export function usageData(state) {
  return _.get(state, "user.usageData", false);
}

export function getAccount(state) {
  return _.get(state, "user.account", {});
}

export function getAlias(state) {
  return _.get(state, "user.alias", {});
}

export function isLoadingAccount(state) {
  return _.get(state, "user.isLoadingAccount", false);
}

export function isLoadingAlias(state) {
  return _.get(state, "user.isLoadingAlias", false);
}

export function getAccountError(state) {
  return _.get(state, "user.loadingAccountError", "");
}

export function getStripePortal(state) {
  return _.get(state, "user.stripePortal", null);
}

export function getStripeCard(state) {
  return _.get(state, "user.stripeCard", null);
}

export function isLoadingStripePortal(state) {
  return _.get(state, "user.isLoadingStripePortal", false);
}

export function isLoadingStripe(state) {
  return _.get(state, "user.isLoadingStripe", false);
}

export function isLoadingInvoices(state) {
  return _.get(state, "user.isLoadingInvoices", false);
}

export function loadingInvoicesError(state) {
  return _.get(state, "user.loadingInvoicesError", "");
}

export function invoices(state) {
  return _.get(state, "user.invoices", []);
}

export function stripeCustomer(state) {
  return _.get(state, "user.stripeCustomer", null);
}

export function isLoadingStripeCustomer(state) {
  return _.get(state, "user.isLoadingStripeCustomer", false);
}

export function loadingStripeCustomerError(state) {
  return _.get(state, "user.loadingStripeCustomerError", "");
}

export function apiKeys(state) {
  return _.get(state, "user.apiKeys", []);
}

export function isLoadingApiKeys(state) {
  return _.get(state, "user.isLoadingApiKeys", false);
}

export function loadingApiKeysError(state) {
  return _.get(state, "user.loadingApiKeysError", "");
}

export function isGeneratingApiKey(state) {
  return _.get(state, "user.isGeneratingApiKey", false);
}

export function generatingApiKeyError(state) {
  return _.get(state, "user.generatingApiKeyError", "");
}

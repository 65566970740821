import { API as AmplifyAPI } from "aws-amplify";
import _ from "lodash";

const lodash = require("lodash");

export const getLaunchStackUrl = (
  values = {
    stackName: "docevent-io-sfs",
    bucketName: "",
    createNewBucket: false,
  }
) => {
  let { stackName, bucketName, createNewBucket } = values;

  if (createNewBucket) createNewBucket = "Create a new bucket with this name";
  else createNewBucket = "This is an existing bucket";

  // check this https://aws.amazon.com/blogs/apn/new-aws-cloudformation-stack-quick-create-links-further-simplify-customer-onboarding/
  // go straight to create stack section (Streamlined Launch Stack Wizard)
  const parameters = `param_BucketName=${bucketName}&param_CreateNewBucket=${createNewBucket}`;
  const templateUrl = process.env.REACT_APP_SFS_STACK + "&" + parameters;
  return (
    `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?stackName=${stackName}&templateURL=` +
    templateUrl
  );
};

export const generateUID = () => {
  let firstPart = (Math.random() * 1679616) | 0;
  let secondPart = (Math.random() * 1679616) | 0;
  firstPart = ("0000" + firstPart.toString(36)).slice(-4);
  secondPart = ("0000" + secondPart.toString(36)).slice(-4);
  return firstPart + secondPart;
};

export const API = {
  get: (apiName, path, init = {}) => {
    return handleApiResult(AmplifyAPI.get(apiName, path, init));
  },
  del: (apiName, path, init = {}) => {
    return handleApiResult(AmplifyAPI.del(apiName, path, init));
  },
  put: (apiName, path, init = {}) => {
    return handleApiResult(AmplifyAPI.put(apiName, path, init));
  },
  patch: (apiName, path, init = {}) => {
    return handleApiResult(AmplifyAPI.patch(apiName, path, init));
  },
  post: (apiName, path, init = {}) => {
    return handleApiResult(AmplifyAPI.post(apiName, path, init));
  },
};

// This makes it easy to handle responses from API.X() method
// 1. If any response arrives back with the errorMessage field, then it is immediately returned as a Promise.reject(w/ errorMessage)
// 2. If no errorMessage arrives and http result is 200, then Promise.resolve(response.data)
// 3. otherwise, if a !200 response arrives and no errorMessage in response, then Promise.reject(response.message)
export const handleApiResult = (
  thePromise,
  options = { rawResponse: false }
) => {
  const { rawResponse } = options;

  return thePromise
    .then((response) => {
      if (lodash.get(response, "errorMessage", null))
        // 200 response but errorMessage returned
        return Promise.reject(response.errorMessage);

      if (lodash.get(response, "data.errorMessage", null))
        // 200 response but errorMessage returned
        return Promise.reject(response.data.errorMessage);

      if (!rawResponse)
        return Promise.resolve(lodash.get(response, "data", null));
      else return Promise.resolve(response);
    })
    .catch((error) => {
      if (typeof error === "string") return Promise.reject(error);

      if (lodash.get(error, "response.data.errorMessage", null))
        // !200 response but errorMessage returned
        return Promise.reject(error.response.data.errorMessage);

      console.log("error", error);
      console.log("error.response", error.response);

      return Promise.reject(error.message);
    });
};

export const sortChannels = (channels) => {
  if (!_.isArray(channels)) {
    return channels;
  }

  return channels.sort((channel1, channel2) => {
    return channel1.name.localeCompare(channel2.name);
  });
};

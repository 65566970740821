import { combineReducers } from "redux";

import sfs from "./sfs/reducer";
import user from "./user/reducer";
import server from "./server/reducer";

const appReducer = combineReducers({
  sfs,
  user,
  server,
});

const rootReducer = (state, action) => {
  if (action.type === "CLEARSTORE") {
    // const { routing } = state
    // state = { routing }
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

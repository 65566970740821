import React from "react";

const LoadingComponent = ({ isLoading, error }) => {
  if (error) {
    return (
      <div
        style={{
          marginTop: "10em",
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        <p>Sorry there was a problem loading the page. Refresh to try again.</p>
      </div>
    );
  }

  // no loading Spinner makes it cleaner when starting
  return null;

  /*
  // Handle the loading state
  if (isLoading) {
    return (
      <div style={{paddingTop: '10em', textAlign: 'center', backgroundColor: 'white'}}>
        <Spin size="large" />
      </div>
    )
  }
  // Handle the error state
  else if (error) {
  }
  else {
    return null;
  }
  */
};

export default LoadingComponent;

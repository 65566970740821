export const FETCH_INSTANCES_REQUEST = "sfs.FETCH_INSTANCES_REQUEST";
export const FETCH_INSTANCES_FAILURE = "sfs.FETCH_INSTANCES_FAILURE";
export const FETCH_INSTANCES_SUCCESS = "sfs.FETCH_INSTANCES_SUCCESS";

export const FETCH_LICENSES_REQUEST = "sfs.FETCH_LICENSES_REQUEST";
export const FETCH_LICENSES_FAILURE = "sfs.FETCH_LICENSES_FAILURE";
export const FETCH_LICENSES_SUCCESS = "sfs.FETCH_LICENSES_SUCCESS";

export const RESET_LICENSES = "sfs.RESET_LICENSES";
export const RESET_INSTANCES = "sfs.RESET_INSTANCES";
export const RESET_INSTANCE = "sfs.RESET_INSTANCE";

export const ACTIVATE_TRIAL_REQUEST = "sfs.ACTIVATE_TRIAL_REQUEST";
export const ACTIVATE_TRIAL_SUCCESS = "sfs.ACTIVATE_TRIAL_SUCCESS";
export const ACTIVATE_TRIAL_FAILURE = "sfs.ACTIVATE_TRIAL_FAILURE";

export const FETCH_INSTANCE_REQUEST = "sfs.FETCH_INSTANCE_REQUEST";
export const FETCH_INSTANCE_FAILURE = "sfs.FETCH_INSTANCE_FAILURE";
export const FETCH_INSTANCE_SUCCESS = "sfs.FETCH_INSTANCE_SUCCESS";

export const FETCH_SERVER_VERSIONS_REQUEST =
  "sfs.FETCH_SERVER_VERSIONS_REQUEST";
export const FETCH_SERVER_VERSIONS_FAILURE =
  "sfs.FETCH_SERVER_VERSIONS_FAILURE";
export const FETCH_SERVER_VERSIONS_SUCCESS =
  "sfs.FETCH_SERVER_VERSIONS_SUCCESS";

export const ADD_SERVICE_SUCCESS = "sfs.ADD_SERVICE_SUCCESS";

export const FETCH_SERVICES_REQUEST = "sfs.FETCH_SERVICES_REQUEST";
export const FETCH_SERVICES_FAILURE = "sfs.FETCH_SERVICES_FAILURE";
export const FETCH_SERVICES_SUCCESS = "sfs.FETCH_SERVICES_SUCCESS";

export const PATCH_SERVICE_REQUEST = "sfs.PATCH_SERVICE_REQUEST";
export const PATCH_SERVICE_SUCCESS = "sfs.PATCH_SERVICE_SUCCESS";
export const PATCH_SERVICE_FAILURE = "sfs.PATCH_SERVICE_FAILURE";
export const PATCH_SERVICE_CLEAR = "sfs.PATCH_SERVICE_CLEAR";

export const TEST_SERVICE_REQUEST = "sfs.TEST_SERVICE_REQUEST";
export const TEST_SERVICE_SUCCESS = "sfs.TEST_SERVICE_SUCCESS";
export const TEST_SERVICE_EXCEPTION = "sfs.TEST_SERVICE_EXCEPTION";

export const DELETE_SERVICE_REQUEST = "sfs.DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_SUCCESS = "sfs.DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAILURE = "sfs.DELETE_SERVICE_FAILURE";

export const PATCH_USER_REQUEST = "sfs.PATCH_USER_REQUEST";
export const PATCH_USER_SUCCESS = "sfs.PATCH_USER_SUCCESS";
export const PATCH_USER_FAILURE = "sfs.PATCH_USER_FAILURE";

export const DELETE_USER_REQUEST = "sfs.DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "sfs.DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "sfs.DELETE_USER_FAILURE";

export const ADD_USER_REQUEST = "sfs.ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "sfs.ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "sfs.ADD_USER_FAILURE";

export const CREATE_SERVICE_REQUEST = "sfs.CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_FAILURE = "sfs.CREATE_SERVICE_FAILURE";
export const CREATE_SERVICE_SUCCESS = "sfs.CREATE_SERVICE_SUCCESS";

export const CREATE_SERVICE_RESET = "sfs.CREATE_SERVICE_RESET";
export const CREATE_SERVICE_CLOUDFORMATION_S3_BUCKET =
  "sfs.CREATE_SERVICE_CLOUDFORMATION_S3_BUCKET";
export const CREATE_SERVICE_NEXT_STEP = "sfs.CREATE_SERVICE_NEXT_STEP";

export const CREATE_SERVICE_VERIFY_ACCESS_REQUEST =
  "sfs.CREATE_SERVICE_VERIFY_ACCESS_REQUEST";
export const CREATE_SERVICE_VERIFY_ACCESS_SUCCESS =
  "sfs.CREATE_SERVICE_VERIFY_ACCESS_SUCCESS";
export const CREATE_SERVICE_VERIFY_ACCESS_FAILURE =
  "sfs.CREATE_SERVICE_VERIFY_ACCESS_FAILURE";

export const GET_LOGS_REQUEST = "sfs.GET_LOGS_REQUEST";
export const GET_LOGS_SUCCESS = "sfs.GET_LOGS_SUCCESS";
export const GET_LOGS_FAILURE = "sfs.GET_LOGS_FAILURE";
export const GET_LOGS_RESET = "sfs.GET_LOGS_RESET";

export const UPDATE_USER_ALIAS = "sfs.UPDATE_USER_ALIAS";

export const GET_USERS_REQUEST = "sfs.GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "sfs.GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "sfs.GET_USERS_FAILURE";
export const GET_USERS_RESET = "sfs.GET_USERS_RESET";

export const GET_FIREWALL_RESET = "sfs.GET_FIREWALL_RESET";
export const GET_FIREWALL_REQUEST = "sfs.GET_FIREWALL_REQUEST";
export const GET_FIREWALL_SUCCESS = "sfs.GET_FIREWALL_SUCCESS";
export const GET_FIREWALL_FAILURE = "sfs.GET_FIREWALL_FAILURE";

export const PATCH_FIREWALL_RESET = "sfs.PATCH_FIREWALL_RESET";
export const PATCH_FIREWALL_REQUEST = "sfs.PATCH_FIREWALL_REQUEST";
export const PATCH_FIREWALL_SUCCESS = "sfs.PATCH_FIREWALL_SUCCESS";
export const PATCH_FIREWALL_FAILURE = "sfs.PATCH_FIREWALL_FAILURE";

import React from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Router } from "react-router-dom";

import Amplify from "aws-amplify";
import config from "./config.js";

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import promiseMiddleware from "redux-promise";

import reducers from "./redux-store/reducers";
import history from "./browser-history";

import ReactGA from "react-ga";

// import registerServiceWorker from './registerServiceWorker';

Sentry.init({
  dsn: "https://b8755dc577e846aeaf425b21e9b4f753@sentry.io/1289441",
});

const createStoreWithMiddleware = applyMiddleware(
  thunkMiddleware,
  promiseMiddleware
)(createStore);
export const store = createStoreWithMiddleware(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

Amplify.configure(config);

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// registerServiceWorker();

import { Auth, Hub } from "aws-amplify";
import React from "react";
import { connect } from "react-redux";
import history from "./browser-history";
import {
  userLoggedIn,
  userLoggingIn,
  userLoggingOut,
} from "./redux-store/user/reducer";
import {
  loginSuccess,
  logoutSuccess,
  logoutUser,
} from "./redux-store/user/actions";
import LoadingComponent from "./components/LoadingComponent";
import Loadable from "react-loadable";

import {
  DashboardOutlined,
  DatabaseOutlined,
  DownOutlined,
  LoadingOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Dropdown, Layout, Menu, Spin } from "antd";

import logo from "./images/docevent_logo.png";
import "./App.css";
import { css } from "glamor";
import { Link, Route, Switch, withRouter } from "react-router-dom";

const WarnOverages = Loadable({
  loader: () => import("./components/WarnOverages"),
  loading: LoadingComponent,
});

const AlertPastDue = Loadable({
  loader: () => import("./components/AlertPastDue"),
  loading: LoadingComponent,
});

const AlertUnverified = Loadable({
  loader: () => import("./components/AlertUnverified"),
  loading: LoadingComponent,
});

const AlertDisabled = Loadable({
  loader: () => import("./components/AlertDisabled"),
  loading: LoadingComponent,
});

const AsyncPrivateApp = Loadable({
  loader: () => import("./containers/private/App"),
  loading: LoadingComponent,
});

const AsyncAuthPage = Loadable({
  loader: () => import("./containers/auth/App"),
  loading: LoadingComponent,
});

const { Header } = Layout;

const styles = {
  logo: {
    width: "200px",
    margin: "10px 18px 0 0",
    float: "left",
  },
  menu: {
    lineHeight: "64px",
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);
    Hub.listen("auth", (data) => {
      this.onAuthEvent(data);
    });
  }

  state = {
    checkingAuthentication: true,
  };

  onAuthEvent = (data) => {
    switch (data.payload.event) {
      case "signOut":
        this.props.dispatch(logoutSuccess());
        break;

      /*
        // this is nto required because handled in user/actions.js loginUser(string, string)
      case 'signIn':
        this.props.dispatch(loginSuccess(data.payload.data))
        break;
         */

      default:
        break;
    }
  };

  componentDidMount = () => {
    if (!this.props.loggedIn) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          this.props.dispatch(loginSuccess(user));
          this.setState({ checkingAuthentication: false });
        })
        .catch((error) => {
          this.setState({ checkingAuthentication: false });
        });
    }
  };

  logout = (e) => {
    this.props.dispatch(logoutUser());
  };

  render() {
    const { location } = this.props;

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <a href={"https://us-east-1." + process.env.REACT_APP_DOMAIN}>
            US East (N. Virginia)
          </a>
        </Menu.Item>
        <Menu.Item key="1">
          <a href={"https://ap-southeast-2." + process.env.REACT_APP_DOMAIN}>
            Asia Pacific (Sydney)
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a href={"https://eu-west-1." + process.env.REACT_APP_DOMAIN}>
            EU West (Ireland)
          </a>
        </Menu.Item>
      </Menu>
    );

    const getSelectedKeys = () => {
      if (location.pathname.match(/^\/sfs/)) return "services";
      if (location.pathname.match(/^\/channel/)) return "services";
      if (location.pathname.match(/^\/self-hosted/)) return "self-hosted";
      if (location.pathname.match(/^\/profile/)) return "profile";
      return null;
    };

    if (!this.state.checkingAuthentication && !this.props.loggedIn) {
      // if the url is something other than /login/* then redirect to /login
      if (!location.pathname.match(/^\/login/)) history.push("/login");
    }

    return (
      <div>
        {this.state.checkingAuthentication ? (
          <div>
            <Header>
              <img src={logo} {...css(styles.logo)} alt="DocEvent" />
            </Header>
            <div style={{ marginTop: "10em", textAlign: "center" }}>
              <Spin size="large" />
            </div>
          </div>
        ) : (
          <div>
            {this.props.loggedIn ? (
              <Layout>
                <Header>
                  <WarnOverages />
                  <WarnOverages />
                  <AlertPastDue />
                  <AlertUnverified />
                  <AlertDisabled />
                  <img src={logo} {...css(styles.logo)} alt="DocEvent" />
                  <Menu
                    theme="dark"
                    mode="horizontal"
                    {...css(styles.menu)}
                    selectedKeys={[getSelectedKeys()]}
                  >
                    <Menu.Item key="services">
                      <Link to="/" label="dashboard">
                        <DashboardOutlined /> Services
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="self-hosted">
                      <Link to="/self-hosted" label="profile">
                        <DatabaseOutlined /> Self-Hosted
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profile">
                      <Link to="/profile/details" label="profile">
                        <UserOutlined /> Account
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="region">
                      <Dropdown overlay={menu} trigger={["click"]}>
                        <button className="dropdown-link-button ant-dropdown-link">
                          {process.env.REACT_APP_AWS_REGION_NAME}{" "}
                          <DownOutlined />
                        </button>
                      </Dropdown>
                    </Menu.Item>

                    <Menu.Item onClick={this.logout}>
                      {this.props.loggingOut && <LoadingOutlined />}
                      {!this.props.loggingOut && <LogoutOutlined />} Logout
                    </Menu.Item>
                  </Menu>
                </Header>

                <AsyncPrivateApp />
              </Layout>
            ) : (
              <Layout>
                <Header>
                  <img src={logo} {...css(styles.logo)} alt="DocEvent" />
                  {!this.state.checkingAuthentication && (
                    <Menu
                      theme="dark"
                      mode="horizontal"
                      {...css(styles.menu)}
                      selectedKeys={["login"]}
                    >
                      <Menu.Item key="/region">
                        <Dropdown overlay={menu} trigger={["click"]}>
                          <button className="dropdown-link-button ant-dropdown-link">
                            {process.env.REACT_APP_AWS_REGION_NAME}{" "}
                            <DownOutlined />
                          </button>
                        </Dropdown>
                      </Menu.Item>
                      <Menu.Item key="login">
                        <Link to="/login" label="login">
                          <LoginOutlined /> Login
                        </Link>
                      </Menu.Item>
                    </Menu>
                  )}
                </Header>
                <Layout>
                  <Switch>
                    <Route exact path="/" component={AsyncAuthPage} />
                    <Route path="/login" component={AsyncAuthPage} />
                  </Switch>
                </Layout>
              </Layout>
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    loggedIn: userLoggedIn(state),
    loggingOut: userLoggingOut(state),
    loggingIn: userLoggingIn(state),
  };
}

export default withRouter(connect(mapStateToProps)(App));

import * as types from "./actionTypes";
import * as selectors from "./reducer";
import { API, Auth } from "aws-amplify";
import history from "../../browser-history";
import { handleApiResult } from "../../helpers/common";
import _ from "lodash";
import moment from "moment-timezone";

export function loginUser(username, password, otp) {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOGIN_REQUEST });

    Auth.signIn(username, password)
      .then((user) => {
        // console.log(user);

        if (
          user.challengeName === "SOFTWARE_TOKEN_MFA" ||
          user.challengeName === "SMS_MFA"
        ) {
          // send the otp code
          Auth.confirmSignIn(user, otp, user.challengeName)
            .then((result) => {
              loginSuccess(user, true)(dispatch, getState);
              history.push("/");
            })
            .catch((err) => {
              // console.log(err);
              dispatch({
                type: types.LOGIN_ERROR,
                errorMessage: err.message,
                notconfirmed: null,
              });
            });
        } else {
          loginSuccess(user)(dispatch, getState);
          history.push("/");
        }
      })
      .catch((err) => {
        let notconfirmed = null;
        if (err.code === "UserNotConfirmedException") notconfirmed = username;

        // console.log(err);
        dispatch({
          type: types.LOGIN_ERROR,
          errorMessage: err.message,
          notconfirmed: notconfirmed,
        });
      });
  };
}

export function mfaChallengeRequired(user) {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOGIN_CHALLENGE, user: user });
  };
}

export function loginSuccess(user) {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOGIN_SUCCESS, user: user });

    handleApiResult(API.get("account-v1", "/login", { response: false }));
    loadAccount()(dispatch, getState);
  };
}

export function logoutSuccess() {
  return async (dispatch, getState) => {
    if (!selectors.userLoggedIn(getState())) return;

    history.push("/login");
    dispatch({ type: types.LOGOUT_SUCCESS });
    dispatch({ type: "CLEARSTORE" });

    // await logoutLiveChat()(dispatch, getState);
  };
}

export function logoutUser() {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOGOUT_REQUEST });

    Auth.signOut()
      .then((result) => {
        logoutSuccess()(dispatch, getState);
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.LOGOUT_ERROR, errorMessage: err.message });
      });
  };
}

export function registerUser(username, password, email) {
  return async (dispatch, getState) => {
    dispatch({ type: types.REGISTER_REQUEST, username: username });

    Auth.signUp({
      username: username,
      password: password,
      attributes: {
        email: email,
      },
    })
      .then((result) => {
        dispatch({ type: types.REGISTER_SUCCESS, result: result });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.REGISTER_ERROR, errorMessage: err.message });
      });
  };
}

export function verifyRegistration(code) {
  return async (dispatch, getState) => {
    dispatch({ type: types.REGISTER_VERIFY_REQUEST });

    selectors.getRegistrationUsername(getState());

    Auth.confirmSignUp(selectors.getRegistrationUsername(getState()), code)
      .then((result) => {
        dispatch({ type: types.REGISTER_VERIFY_SUCCESS, result: result });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: types.REGISTER_VERIFY_ERROR,
          errorMessage: err.message,
        });
      });
  };
}

export function passwordRecovery(username) {
  return async (dispatch, getState) => {
    dispatch({ type: types.RECOVERY_REQUEST });

    Auth.forgotPassword(username)
      .then((result) => {
        dispatch({
          type: types.RECOVERY_REQUEST_SUCCESS,
          codeDeliveryDetails: result.CodeDeliveryDetails,
          username: username,
        });

        // CodeDeliveryDetails.AttributeName: email
        // CodeDeliveryDetails.DeliveryMedium: EMAIL
        // CodeDeliveryDetails.Destination: "m***@s**.au"
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: types.RECOVERY_REQUEST_ERROR,
          errorMessage: err.message,
        });
      });
  };
}

export function resetPassword(username, code, password) {
  return async (dispatch, getState) => {
    dispatch({ type: types.RESET_PASSWORD_REQUEST });

    Auth.forgotPasswordSubmit(username, code, password)
      .then((result) => {
        dispatch({
          type: types.RESET_PASSWORD_SUCCESS,
          // codeDeliveryDetails: result.CodeDeliveryDetails,
          // username: username
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: types.RESET_PASSWORD_ERROR,
          errorMessage: err.message,
        });
      });
  };
}

export function resetPasswordReset() {
  return async (dispatch, getState) => {
    dispatch({ type: types.RESET_PASSWORD_RESET });
  };
}

export function resetRegistration() {
  return async (dispatch, getState) => {
    dispatch({ type: types.REGISTER_RESET });
  };
}

export function resetLogin() {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOGIN_RESET });
  };
}

export function loadUsageDataPromise() {
  return handleApiResult(API.get("account-v1", "/usage", { response: true }));
}

export function loadAccountPromise() {
  return handleApiResult(API.get("account-v1", "/", { response: true }));
}

export function loadUsageData() {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_USAGE_DATA_REQUEST });

    loadUsageDataPromise()
      .then((data) => {
        dispatch({ type: types.LOAD_USAGE_DATA_SUCCESS, usageData: data });
      })
      .catch((errorMessage) => {
        dispatch({
          type: types.LOAD_USAGE_DATA_ERROR,
          errorMessage: errorMessage,
        });
      });
  };
}

/*
export function logoutLiveChat() {
  return async (dispatch, getState) => {
    if (_.isObject(window.LiveChatWidget)) {
      // window.LiveChatWidget.call("set_customer_name", null);
      // window.LiveChatWidget.call("set_customer_email", null);
    }
  };
}
 */

export function setChatraDetails() {
  return async (dispatch, getState) => {
    const state = getState();

    const email = _.get(state, "user.account.email");
    const username = _.get(state, "user.account.username");
    const sub = _.get(state, "user.account.sub");
    const stripe = _.get(state, "user.account.stripe");
    const subscription = _.get(state, "user.account.subscription.type");
    const created = _.get(state, "user.account.created");

    if (_.isObject(window.Chatra)) {
      window.Chatra("setIntegrationData", {
        username: username,
        created: moment(created).fromNow() + " (" + created + ")",
        email: email,
        sub: sub,
        stripe: stripe,
        subscription: subscription,
      });
    }
  };
}

export function loadAccount() {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_ACCOUNT_REQUEST });

    handleApiResult(API.get("account-v1", "/", { response: true }))
      .then((data) => {
        dispatch({ type: types.LOAD_ACCOUNT_SUCCESS, account: data });
        setChatraDetails()(dispatch, getState);
      })
      .catch((errorMessage) => {
        dispatch({
          type: types.LOAD_ACCOUNT_FAILURE,
          errorMessage: errorMessage,
        });
      });
  };
}

export function loadStripePortal(path) {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_STRIPE_PORTAL_REQUEST });

    const body = {
      region: process.env.REACT_APP_AWS_REGION,
      path: path,
    };

    handleApiResult(API.post("stripe-v1", "/portal", { body: body }), {
      rawResponse: true,
    })
      .then((data) => {
        dispatch({
          type: types.LOAD_STRIPE_PORTAL_SUCCESS,
          stripePortal: data,
        });
      })
      .catch((errorMessage) => {
        dispatch({
          type: types.LOAD_STRIPE_PORTAL_FAILURE,
          errorMessage: errorMessage,
        });
      });
  };
}

export function loadStripe() {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_STRIPE_REQUEST });

    handleApiResult(API.get("stripe-v1", "/source", {}), { rawResponse: true })
      .then((data) => {
        dispatch({ type: types.LOAD_STRIPE_SUCCESS, stripeCard: data });
      })
      .catch((errorMessage) => {
        dispatch({
          type: types.LOAD_STRIPE_FAILURE,
          errorMessage: errorMessage,
        });
      });
  };
}

export function loadInvoices() {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_INVOICES_REQUEST });

    handleApiResult(API.get("stripe-v1", "/invoices", {}), {
      rawResponse: true,
    })
      .then((data) => {
        dispatch({ type: types.LOAD_INVOICES_SUCCESS, result: data });
      })
      .catch((errorMessage) => {
        dispatch({
          type: types.LOAD_INVOICES_FAILURE,
          errorMessage: errorMessage,
        });
      });
  };
}

export function loadStripeCustomer() {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_STRIPE_CUSTOMER_REQUEST });

    handleApiResult(API.get("stripe-v1", "/", {}), { rawResponse: true })
      .then((data) => {
        dispatch({ type: types.LOAD_STRIPE_CUSTOMER_SUCCESS, result: data });
      })
      .catch((errorMessage) => {
        dispatch({
          type: types.LOAD_STRIPE_CUSTOMER_FAILURE,
          errorMessage: errorMessage,
        });
      });
  };
}

export function deleteApiKey(
  id,
  callbackSuccess = function () {},
  callbackFailure = function () {}
) {
  return async (dispatch, getState) => {
    dispatch({ type: types.DELETE_API_KEY_REQUEST });

    handleApiResult(
      API.del("api-v1", "/api_key/" + encodeURIComponent(id), {}),
      {}
    )
      .then(() => {
        dispatch({ type: types.DELETE_API_KEY_SUCCESS });
        callbackSuccess();
        loadApiKeys()(dispatch, getState);
      })
      .catch((errorMessage) => {
        callbackFailure(errorMessage);
        dispatch({
          type: types.DELETE_API_KEY_FAILURE,
          errorMessage: errorMessage,
        });
      });
  };
}

export function generateApiKey() {
  return async (dispatch, getState) => {
    dispatch({ type: types.GENERATE_API_KEY_REQUEST });

    handleApiResult(API.put("api-v1", "/api_key", {}), { rawResponse: true })
      .then((data) => {
        dispatch({ type: types.GENERATE_API_KEY_SUCCESS, result: data });
      })
      .catch((errorMessage) => {
        dispatch({
          type: types.GENERATE_API_KEY_FAILURE,
          errorMessage: errorMessage,
        });
      });
  };
}

export function loadApiKeys() {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_API_KEYS_REQUEST });

    handleApiResult(API.get("api-v1", "/api_keys", {}), { rawResponse: true })
      .then((data) => {
        dispatch({ type: types.LOAD_API_KEYS_SUCCESS, result: data });
      })
      .catch((errorMessage) => {
        dispatch({
          type: types.LOAD_API_KEYS_FAILURE,
          errorMessage: errorMessage,
        });
      });
  };
}

import { Auth } from "aws-amplify";

async function idToken() {
  return (await Auth.currentSession()).idToken.jwtToken;
}

const config = {
  Analytics: {
    autoSessionRecord: false,
    disabled: true,
  },
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: process.env.REACT_APP_COOKIE_PATH,
      expires: parseInt(process.env.REACT_APP_COOKIE_EXPIRES, 10),
      secure: process.env.REACT_APP_COOKIE_SECURE === "true",
    },
  },

  // Cognito configuration // now moved up in the Auth section
  // aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  // aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  // aws_sign_in_enabled: 'enable',

  // User pools configuration
  // aws_user_pools: 'enable',
  // aws_user_pools_mfa_type: 'OFF',
  // aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
  // aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,

  // API Endpoints configuration
  API: {
    endpoints: [
      {
        name: "account-v1",
        endpoint: process.env.REACT_APP_API_BASE + "/account-v1",
        custom_header: async () => {
          return { Authorization: await idToken() };
        },
      },
      {
        name: "channel-v1",
        endpoint: process.env.REACT_APP_API_BASE + "/channel-v1",
        custom_header: async () => {
          return { Authorization: await idToken() };
        },
      },
      {
        name: "play-v1",
        endpoint: process.env.REACT_APP_API_BASE + "/play-v1",
        custom_header: async () => {
          return { Authorization: await idToken() };
        },
      },
      {
        name: "public-v1",
        endpoint: process.env.REACT_APP_API_BASE + "/public-v1",
        custom_header: async () => {
          return { Authorization: await idToken() };
        },
      },
      {
        name: "server-v1",
        endpoint: process.env.REACT_APP_API_BASE + "/server-v1",
        custom_header: async () => {
          return { Authorization: await idToken() };
        },
      },
      {
        name: "sfs-v1",
        endpoint: process.env.REACT_APP_API_BASE + "/sfs-v1",
        custom_header: async () => {
          return { Authorization: await idToken() };
        },
      },
      {
        name: "stripe-v1",
        endpoint: process.env.REACT_APP_API_BASE + "/stripe-v1",
        custom_header: async () => {
          return { Authorization: await idToken() };
        },
      },
      {
        name: "api-v1",
        endpoint: process.env.REACT_APP_API_BASE + "/api-v1",
        custom_header: async () => {
          return { Authorization: await idToken() };
        },
      },
      {
        name: "channel-v1",
        endpoint: process.env.REACT_APP_API_BASE + "/channel-v1",
        custom_header: async () => {
          return { Authorization: await idToken() };
        },
      },
    ],
  },
};

export default config;

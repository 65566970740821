import * as types from "./actionTypes";
import Immutable from "seamless-immutable";
import _ from "lodash";

const initialStateCreating = {
  creating: false,
  step: 0, // 0 = Create AWS Keys
  // 1 = Provide Access
  // 2 = Create Service
  // 3 = Done
  keySource: 0, // 0 = creating new stack + new bucket
  // 1 = creating new stack + existing bucket
  // 2 = using existing keys
  bucketName: "",
  storageType: "",
  azureContainer: "",
  stackName: "",
  verify: {},
  error: false,
  errorMessage: null,
};

const initialState = Immutable({
  loading: false,
  success: false,
  error: false,
  errorMessage: "",

  services: [],
  users: [],

  patching: {
    patching: false,
    success: false,
    error: false,
    errorMessage: null,
  },

  deleting: {
    deleting: false,
    success: false,
    error: false,
    errorMessage: null,
  },

  creating: initialStateCreating,

  isSearchingLogs: false,
  searchingLogsResult: [],
  searchingLogsErrorMessage: "",
});

function sortUsers(users) {
  if (users.sort === undefined) {
    return null;
  }

  return users.sort((user1, user2) => {
    return _.get(user1, "username", "").localeCompare(
      _.get(user2, "username", "")
    );
  });
}

function sortServices(services) {
  if (services.sort === undefined) {
    return null;
  }

  return services.sort((service1, service2) => {
    return _.get(service1, "config.name", "").localeCompare(
      _.get(service2, "config.name", "")
    );
  });
}

export default function reduce(state = initialState, action = {}) {
  let mutableServices = [];

  switch (action.type) {
    case types.ADD_SERVICE_SUCCESS:
      mutableServices = state.services.asMutable();
      mutableServices.push(action.service);

      return state.merge({
        services: mutableServices,
      });

    case types.FETCH_SERVICES_REQUEST:
      return state.merge({
        success: false,
        loading: true,
        error: false,
      });

    case types.FETCH_SERVICES_SUCCESS:
      return state.merge({
        success: true,
        loading: false,
        error: false,
        services: action.services,
      });

    case types.FETCH_SERVICES_FAILURE:
      return state.merge({
        success: false,
        loading: false,
        error: true,
      });

    case types.PATCH_SERVICE_REQUEST:
      return state.setIn(["patching"], {
        patching: true,
        success: false,
        error: false,
        errorMessage: "",
      });

    case types.PATCH_SERVICE_SUCCESS:
      return state
        .updateIn(["services"], function (list) {
          let idx = list.findIndex(function (service) {
            return service.uuid === action.uuid;
          });
          return list.setIn([idx, "config"], action.config);
        })
        .setIn(["patching"], {
          patching: false,
          success: true,
          error: false,
          errorMessage: "",
        });

    case types.PATCH_SERVICE_FAILURE:
      return state.setIn(["patching"], {
        patching: false,
        success: false,
        error: true,
        errorMessage: action.error,
      });

    case types.PATCH_SERVICE_CLEAR:
      return state.setIn(["patching"], {
        patching: false,
        success: false,
        error: false,
        errorMessage: null,
      });

    case types.DELETE_SERVICE_REQUEST:
      return state.setIn(["deleting"], {
        deleting: true,
        success: false,
        error: false,
        errorMessage: null,
      });

    case types.DELETE_SERVICE_SUCCESS:
      // remove the service from the state
      mutableServices = state.services.asMutable();
      mutableServices = _.remove(mutableServices, function (service) {
        // remove all but the one we deleted
        return service.uuid !== action.uuid;
      });

      return state
        .setIn(["deleting"], {
          deleting: false,
          success: true,
          error: false,
          errorMessage: null,
        })
        .merge({
          services: mutableServices, // existing the services with this one removed
        });

    case types.DELETE_SERVICE_FAILURE:
      return state.setIn(["deleting"], {
        deleting: false,
        success: false,
        error: true,
        errorMessage: action.error,
      });

    case types.DELETE_USER_SUCCESS:
      return state.merge({
        users: action.users,
      });

    case types.ADD_USER_SUCCESS:
      let mutableUsers = state.users.asMutable();
      mutableUsers.push(action.user);
      return state.merge({
        users: mutableUsers,
      });

    case types.CREATE_SERVICE_CLOUDFORMATION_S3_BUCKET:
      return state.merge(
        {
          creating: {
            bucketName: action.bucketName,
            stackName: action.stackName,
            keySource: 0,
          },
        },
        { deep: true }
      );

    case types.CREATE_SERVICE_NEXT_STEP:
      return state.merge(
        {
          creating: {
            step: state.creating.step + 1,
          },
        },
        { deep: true }
      );

    case types.CREATE_SERVICE_RESET:
      return state.merge({
        creating: initialStateCreating,
        deleting: {
          deleting: false,
          success: false,
          error: false,
          errorMessage: null,
        },
      });

    case types.CREATE_SERVICE_VERIFY_ACCESS_REQUEST:
      return state.merge(
        {
          creating: {
            verify: {
              success: false,
              testing: true,
              storageType: action.storageType,
              bucketName: action.bucketName,
              googleServiceAccount: action.googleServiceAccount,
              awsAccessKeyId: action.awsAccessKeyId,
              awsSecretAccessKey: action.awsSecretAccessKey,
              azureStorageAccount: action.azureStorageAccount,
              azureStorageURL: action.azureStorageURL,
              azureStorageContainer: action.azureStorageContainer,
              azureStorageShare: action.azureStorageShare,
              azureStorageAccessKey: action.azureStorageAccessKey,
              testPath: action.testPath,
              ignoreVerificationResults: action.ignoreVerificationResults,
              error: false,
              errorMessage: null,
            },
          },
        },
        { deep: true }
      );

    case types.CREATE_SERVICE_VERIFY_ACCESS_FAILURE:
      return state.merge(
        {
          creating: {
            verify: {
              success: false,
              testing: false,
              error: true,
              errorMessage: action.error,
            },
          },
        },
        { deep: true }
      );

    case types.CREATE_SERVICE_VERIFY_ACCESS_SUCCESS:
      return state
        .merge(
          {
            creating: {
              verify: {
                success: true,
                testing: false,
                error: false,
                errorMessage: null,
              },
            },
          },
          { deep: true }
        )
        .merge(
          {
            creating: {
              storageType: action.storageType,
              bucketName: action.bucketName,
              googleServiceAccount: action.googleServiceAccount,
              awsAccessKeyId: action.awsAccessKeyId,
              awsSecretAccessKey: action.awsSecretAccessKey,
              azureStorageAccount: action.azureStorageAccount,
              azureStorageURL: action.azureStorageURL,
              azureStorageContainer: action.azureStorageContainer,
              azureStorageShare: action.azureStorageShare,
              azureStorageAccessKey: action.azureStorageAccessKey,
              s3CompatKeySecret: action.s3CompatKeySecret,
              s3CompatEndpointUrl: action.s3CompatEndpointUrl,
              s3CompatAccessKeyId: action.s3CompatAccessKeyId,
              s3CompatRegion: action.s3CompatRegion,
              testPath: action.testPath,
              ignoreVerificationResults: action.ignoreVerificationResults,
            },
          },
          { deep: true }
        );

    case types.CREATE_SERVICE_REQUEST:
      return state.merge(
        {
          creating: {
            creating: true,
            uuid: action.uuid,
            name: action.name,
            description: action.description,
            initialUsername: action.initialUsername,
            initialPassword: action.initialPassword,
            initialHome: action.initialHome,
          },
        },
        { deep: true }
      );

    case types.CREATE_SERVICE_FAILURE:
      return state.merge(
        {
          creating: {
            creating: false,
            error: true,
            errorMessage: action.error,
          },
        },
        { deep: true }
      );

    case types.CREATE_SERVICE_SUCCESS:
      return state.merge(
        {
          creating: {
            creating: false,
            created: action.result,
            error: false,
            errorMessage: null,
          },
        },
        { deep: true }
      );

    case types.GET_LOGS_REQUEST:
      return state.merge({
        isSearchingLogs: true,
        searchingLogsErrorMessage: "",
      });

    case types.GET_LOGS_RESET:
      return state.merge({
        isSearchingLogs: false,
        searchingLogsErrorMessage: "",
        searchingLogsResult: [],
      });

    case types.GET_LOGS_SUCCESS:
      return state.merge({
        isSearchingLogs: false,
        searchingLogsResult: action.result,
        searchingLogsErrorMessage: "",
      });

    case types.GET_LOGS_FAILURE:
      return state.merge({
        isSearchingLogs: false,
        searchingLogsErrorMessage: action.error,
      });

    case types.UPDATE_USER_ALIAS:
      let mutableUsers2 = state.users.asMutable();
      // find the user alias that we are updating, and set the alias value
      for (let i = 0; i < mutableUsers2.length; i++) {
        if (mutableUsers2[i].username === action.user.username) {
          let user = mutableUsers2[i].asMutable();

          user.alias = action.alias;
          mutableUsers2[i] = user;
        }
      }

      return state.merge({
        users: mutableUsers2,
      });

    case types.GET_USERS_REQUEST:
      return state.merge({
        isFetchingUsers: true,
        fetchingUserErrorMessage: "",
      });

    case types.GET_USERS_SUCCESS:
      return state.merge({
        isFetchingUsers: false,
        users: action.users,
        fetchingUserErrorMessage: "",
      });

    case types.GET_USERS_FAILURE:
      return state.merge({
        isFetchingUsers: false,
        fetchingUserErrorMessage: action.error,
      });

    case types.GET_USERS_RESET:
      return state.merge({
        isFetchingUsers: false,
        users: [],
        fetchingUserErrorMessage: "",
      });

    case types.GET_FIREWALL_RESET:
      return state.merge({
        isFetchingFirewall: false,
        firewall: null,
        fetchingFirewallErrorMessage: "",
      });

    case types.GET_FIREWALL_REQUEST:
      return state.merge({
        isFetchingFirewall: true,
        fetchingFirewallErrorMessage: "",
      });

    case types.GET_FIREWALL_SUCCESS:
      return state.merge({
        isFetchingFirewall: false,
        firewall: action.firewall,
        fetchingFirewallErrorMessage: "",
      });

    case types.GET_FIREWALL_FAILURE:
      return state.merge({
        isFetchingFirewall: false,
        fetchingFirewallErrorMessage: action.error,
      });

    case types.PATCH_FIREWALL_RESET:
      return state.merge({
        didPatchFirewall: false,
        patchingFirewallErrorMessage: "",
      });

    case types.PATCH_FIREWALL_REQUEST:
      return state.merge({
        isPatchingFirewall: true,
        didPatchFirewall: false,
        patchingFirewallErrorMessage: "",
      });

    case types.PATCH_FIREWALL_SUCCESS:
      return state.merge({
        isPatchingFirewall: false,
        didPatchFirewall: true,
        firewall: action.firewall,
        patchingFirewallErrorMessage: "",
      });

    case types.PATCH_FIREWALL_FAILURE:
      return state.merge({
        isPatchingFirewall: false,
        patchingFirewallErrorMessage: action.error,
      });

    default:
      return state;
  }
}

// Selectors for patching
export function servicePatchingSuccess(state) {
  return _.get(state, "sfs.patching.success", false);
}

export function servicePatchingError(state) {
  return _.get(state, "sfs.patching.error", false);
}

export function servicePatchingErrorMessage(state) {
  return _.get(state, "sfs.patching.errorMessage", null);
}

export function serviceIsPatching(state) {
  return _.get(state, "sfs.patching.patching", false);
}

// Selectors for deleting
export function serviceDeletingSuccess(state) {
  return _.get(state, "sfs.deleting.success", false);
}

export function serviceDeletingError(state) {
  return _.get(state, "sfs.deleting.error", false);
}

export function serviceDeletingErrorMessage(state) {
  return _.get(state, "sfs.deleting.errorMessage", null);
}

export function serviceIsDeleting(state) {
  return _.get(state, "sfs.deleting.deleting", false);
}

// Selectors for Users
export function getUsersArray(state) {
  let users = _.get(state, "sfs.users", Immutable([]));
  return Immutable(sortUsers(users.asMutable()));
}

export function isFetchingUsers(state) {
  return _.get(state, "sfs.isFetchingUsers", []);
}

// Selectors for services
export function isLoaded(state) {
  return state.sfs.success;
}

export function getState(state) {
  return state.sfs;
}

export function serviceExistsUuid(state, uuid) {
  const service = getService(state, uuid);

  if (service === null) return false;

  return true;
}

export function getExampleUsernameUuid(state, uuid) {
  const service = getService(state, uuid);

  return _.get(service, "config.users[0].name", "example");
}

export function getService(state, uuid) {
  return _.find(state.sfs.services, { uuid: uuid });
}

export function getServices(state) {
  return Immutable(sortServices(state.sfs.services.asMutable()));
}

export function isLoading(state) {
  return state.sfs.loading;
}

export function isError(state) {
  return state.sfs.error;
}

export function serviceCreatingGetData(state) {
  return {
    uuid: _.get(state, "sfs.creating.uuid", ""),
    config: serviceCreatingGetConfig(state),
  };
}

export function serviceCreatingGetConfig(state) {
  return {
    name: _.get(state, "sfs.creating.name", ""),
    description: _.get(state, "sfs.creating.description", ""),
    bucket: _.get(state, "sfs.creating.bucketName", ""),
    google_service_account: _.get(
      state,
      "sfs.creating.googleServiceAccount",
      ""
    ),
    storage_type: _.get(state, "sfs.creating.storageType", ""),
    aws_access_key_id: _.get(state, "sfs.creating.awsAccessKeyId", ""),
    aws_secret_access_key: _.get(state, "sfs.creating.awsSecretAccessKey", ""),
    azure_storage_account: _.get(state, "sfs.creating.azureStorageAccount", ""),
    azure_storage_url: _.get(state, "sfs.creating.azureStorageURL", ""),
    azure_storage_container: _.get(
      state,
      "sfs.creating.azureStorageContainer",
      ""
    ),
    azure_storage_share: _.get(state, "sfs.creating.azureStorageShare", ""),
    azure_storage_access_key: _.get(
      state,
      "sfs.creating.azureStorageAccessKey",
      ""
    ),
    s3_compat_key_secret: _.get(state, "sfs.creating.s3CompatKeySecret", ""),
    s3_compat_endpoint_url: _.get(
      state,
      "sfs.creating.s3CompatEndpointUrl",
      ""
    ),
    s3_compat_access_key_id: _.get(
      state,
      "sfs.creating.s3CompatAccessKeyId",
      ""
    ),
    s3_compat_region: _.get(state, "sfs.creating.s3CompatRegion", ""),
    test_path: _.get(state, "sfs.creating.testPath", ""),
    ignore_verification_results: _.get(
      state,
      "sfs.creating.ignoreVerificationResults",
      false
    ),
    user: {
      username: _.get(state, "sfs.creating.initialUsername", ""),
      password: _.get(state, "sfs.creating.initialPassword", ""),
      home: _.get(state, "sfs.creating.initialHome", ""),
    },
  };
}

// Selectors for creating a new service
export function serviceCreatingBucketName(state) {
  return _.get(state, "sfs.creating.bucketName", "");
}

export function serviceCreatingAzureShare(state) {
  return _.get(state, "sfs.creating.azureShare", "");
}

export function serviceCreatingAzureContainer(state) {
  return _.get(state, "sfs.creating.azureContainer", "");
}

export function serviceCreatingStackName(state) {
  return _.get(state, "sfs.creating.stackName", "");
}

export function serviceCreatingCurrentStep(state) {
  return _.get(state, "sfs.creating.step", 0);
}

export function serviceCreatingIsTesting(state) {
  return _.get(state, "sfs.creating.verify.testing", false);
}

export function serviceCreatingTestingIsError(state) {
  return _.get(state, "sfs.creating.verify.error", false);
}

export function serviceCreatingTestingErrorMessage(state) {
  return _.get(state, "sfs.creating.verify.errorMessage", null);
}

export function serviceIsCreating(state) {
  return _.get(state, "sfs.creating.creating", false);
}

export function serviceIsCreatingError(state) {
  return _.get(state, "sfs.creating.error", false);
}

export function serviceIsCreatingErrorMessage(state) {
  return _.get(state, "sfs.creating.errorMessage", null);
}

export function serviceCreatingGetCreated(state) {
  return _.get(state, "sfs.creating.created", {});
}

export function searchingLogsErrorMessage(state) {
  return _.get(state, "sfs.searchingLogsErrorMessage", "");
}

export function searchingLogsResult(state) {
  return _.get(state, "sfs.searchingLogsResult", []);
}

export function isSearchingLogs(state) {
  return _.get(state, "sfs.isSearchingLogs", false);
}

export function isFetchingFirewall(state) {
  return _.get(state, "sfs.isFetchingFirewall", false);
}

export function isPatchingFirewall(state) {
  return _.get(state, "sfs.isPatchingFirewall", false);
}

export function getFirewall(state) {
  return _.get(state, "sfs.firewall", {});
}

export function patchingFirewallErrorMessage(state) {
  return _.get(state, "sfs.patchingFirewallErrorMessage", "");
}

export function fetchingFirewallErrorMessage(state) {
  return _.get(state, "sfs.fetchingFirewallErrorMessage", "");
}

export function didPatchFirewall(state) {
  return _.get(state, "sfs.didPatchFirewall", false);
}

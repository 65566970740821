export const LOGIN_RESET = "user.LOGIN_RESET";
export const LOGIN_REQUEST = "user.LOGIN_REQUEST";
export const LOGIN_ERROR = "user.LOGIN_ERROR";
export const LOGIN_CHALLENGE = "user.LOGIN_CHALLENGE";
export const LOGIN_SUCCESS = "user.LOGIN_SUCCESS";

export const LOGOUT_REQUEST = "user.LOGOUT_REQUEST";
export const LOGOUT_ERROR = "user.LOGOUT_ERROR";
export const LOGOUT_SUCCESS = "user.LOGOUT_SUCCESS";

export const REGISTER_RESET = "user.REGISTER_RESET";
export const REGISTER_REQUEST = "user.REGISTER_REQUEST";
export const REGISTER_ERROR = "user.REGISTER_ERROR";
export const REGISTER_SUCCESS = "user.REGISTER_SUCCESS";
export const REGISTER_VERIFY_REQUEST = "user.REGISTER_VERIFY_REQUEST";
export const REGISTER_VERIFY_SUCCESS = "user.REGISTER_VERIFY_SUCCESS";
export const REGISTER_VERIFY_ERROR = "user.REGISTER_VERIFY_ERROR";

export const RECOVERY_REQUEST = "user.RECOVERY_REQUEST";
export const RECOVERY_REQUEST_ERROR = "user.RECOVERY_REQUEST_ERROR";
export const RECOVERY_REQUEST_SUCCESS = "user.RECOVERY_REQUEST_SUCCESS";

export const RESET_PASSWORD_REQUEST = "user.RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "user.RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "user.RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_RESET = "user.RESET_PASSWORD_RESET";

export const LOAD_USAGE_DATA_REQUEST = "user.LOAD_USAGE_DATA_REQUEST";
export const LOAD_USAGE_DATA_SUCCESS = "user.LOAD_USAGE_DATA_SUCCESS";
export const LOAD_USAGE_DATA_ERROR = "user.LOAD_USAGE_DATA_ERROR";

export const LOAD_ACCOUNT_REQUEST = "user.LOAD_ACCOUNT_REQUEST";
export const LOAD_ACCOUNT_SUCCESS = "user.LOAD_ACCOUNT_SUCCESS";
export const LOAD_ACCOUNT_FAILURE = "user.LOAD_ACCOUNT_FAILURE";

export const LOAD_STRIPE_PORTAL_REQUEST = "user.LOAD_STRIPE_PORTAL_REQUEST";
export const LOAD_STRIPE_PORTAL_SUCCESS = "user.LOAD_STRIPE_PORTAL_SUCCESS";
export const LOAD_STRIPE_PORTAL_FAILURE = "user.LOAD_STRIPE_PORTAL_FAILURE";

export const LOAD_STRIPE_REQUEST = "user.LOAD_STRIPE_REQUEST";
export const LOAD_STRIPE_SUCCESS = "user.LOAD_STRIPE_SUCCESS";
export const LOAD_STRIPE_FAILURE = "user.LOAD_STRIPE_FAILURE";

export const LOAD_INVOICES_REQUEST = "user.LOAD_INVOICES_REQUEST";
export const LOAD_INVOICES_SUCCESS = "user.LOAD_INVOICES_SUCCESS";
export const LOAD_INVOICES_FAILURE = "user.LOAD_INVOICES_FAILURE";

export const LOAD_ALIAS_REQUEST = "user.LOAD_ALIAS_REQUEST";
export const LOAD_ALIAS_SUCCESS = "user.LOAD_ALIAS_SUCCESS";
export const LOAD_ALIAS_FAILURE = "user.LOAD_ALIAS_FAILURE";

export const LOAD_STRIPE_CUSTOMER_REQUEST = "user.LOAD_STRIPE_CUSTOMER_REQUEST";
export const LOAD_STRIPE_CUSTOMER_SUCCESS = "user.LOAD_STRIPE_CUSTOMER_SUCCESS";
export const LOAD_STRIPE_CUSTOMER_FAILURE = "user.LOAD_STRIPE_CUSTOMER_FAILURE";

export const LOAD_API_KEYS_REQUEST = "user.LOAD_API_KEYS_REQUEST";
export const LOAD_API_KEYS_SUCCESS = "user.LOAD_API_KEYS_SUCCESS";
export const LOAD_API_KEYS_FAILURE = "user.LOAD_API_KEYS_FAILURE";

export const GENERATE_API_KEY_REQUEST = "user.GENERATE_API_KEY_REQUEST";
export const GENERATE_API_KEY_SUCCESS = "user.GENERATE_API_KEY_SUCCESS";
export const GENERATE_API_KEY_FAILURE = "user.GENERATE_API_KEY_FAILURE";

export const DELETE_API_KEY_REQUEST = "user.DELETE_API_KEY_REQUEST";
export const DELETE_API_KEY_SUCCESS = "user.DELETE_API_KEY_SUCCESS";
export const DELETE_API_KEY_FAILURE = "user.DELETE_API_KEY_FAILURE";

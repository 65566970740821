import * as types from "./actionTypes";
import Immutable from "seamless-immutable";
import _ from "lodash";

const initialLicenses = Immutable({
  licenseTrialAvailable: false,

  loadingLicenses: false,
  licenses: [],
  licensesError: null,

  activatingTrial: false,
  activatingTrialError: null,
});

const initialVersions = Immutable({
  versions: {},
  loadedVersions: false,
  loadingVersions: false,
  loadingVersionsError: null,
});

const initialInstances = Immutable({
  instances: [],
  loadingInstances: false,
  loadingInstancesError: null,
});

const initialInstance = Immutable({
  instance: {},
  loadingInstance: false,
  loadingInstanceError: null,
});

const initialState = Immutable({
  ...initialInstance,
  ...initialInstances,
  ...initialLicenses,
  ...initialVersions,
});

/*
function sortServers(services) {
  if(services.sort === undefined) {
    return null
  }

  return services.sort((service1, service2) => {
    return _.get(service1, 'config.name', '').localeCompare(_.get(service2, 'config.name', ''))
  })
}
*/

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.RESET_LICENSES:
      return state.merge(initialLicenses);

    case types.FETCH_SERVER_VERSIONS_REQUEST:
      return state.merge({
        loadingVersions: true,
        loadingVersionsError: null,
      });

    case types.FETCH_SERVER_VERSIONS_SUCCESS:
      return state.merge({
        loadingVersions: false,
        loadedVersions: true,
        versions: action.versions,
        loadingVersionsError: null,
      });

    case types.FETCH_LICENSES_REQUEST:
      return state.merge({
        loadingLicenses: true,
        licensesError: null,
      });

    case types.FETCH_LICENSES_SUCCESS:
      return state.merge({
        loadingLicenses: false,
        licensesError: null,
        licenses: action.licenses,
        licenseTrialAvailable: action.trial_available,
      });

    case types.FETCH_LICENSES_FAILURE:
      return state.merge({
        loadingLicenses: false,
        licensesError: action.error,
      });

    case types.ACTIVATE_TRIAL_REQUEST:
      return state.merge({
        activatingTrial: true,
        activatingTrialError: null,
      });

    case types.ACTIVATE_TRIAL_SUCCESS:
      let mutableLicenses = state.licenses.asMutable();
      mutableLicenses.push(action.license);

      return state.merge({
        licenseTrialAvailable: false,
        activatingTrial: false,
        activatingTrialError: null,
        licenses: mutableLicenses,
      });

    case types.ACTIVATE_TRIAL_FAILURE:
      return state.merge({
        activatingTrial: false,
        activatingTrialError: action.error,
      });

    case types.RESET_INSTANCES:
      return state.merge(initialInstances);

    case types.FETCH_INSTANCES_REQUEST:
      return state.merge({
        loadingInstances: true,
        loadingInstancesError: null,
      });

    case types.FETCH_INSTANCES_SUCCESS:
      return state.merge({
        loadingInstances: false,
        loadingInstancesError: null,
        instances: action.instances,
      });

    case types.FETCH_INSTANCES_FAILURE:
      return state.merge({
        loadingInstances: false,
        loadingInstancesError: action.error,
      });

    case types.RESET_INSTANCE:
      return state.merge(initialInstance);

    case types.FETCH_INSTANCE_REQUEST:
      return state.merge({
        loadingInstance: true,
        loadingInstanceError: null,
      });

    case types.FETCH_INSTANCE_SUCCESS:
      return state.merge({
        loadingInstance: false,
        loadingInstanceError: null,
        instance: action.instance,
      });

    case types.FETCH_INSTANCE_FAILURE:
      return state.merge({
        loadingInstance: false,
        loadingInstanceError: action.error,
      });

    default:
      return state;
  }
}

export function getInstance(state) {
  return _.get(state, "server.instance", {});
}

export function isLoadingInstance(state) {
  return _.get(state, "server.loadingInstance", false);
}

export function loadingInstanceError(state) {
  return _.get(state, "server.loadingInstanceError", null);
}

export function getInstances(state) {
  return _.get(state, "server.instances", []);
}

export function isLoadingInstances(state) {
  return _.get(state, "server.loadingInstances", false);
}

export function loadingInstancesError(state) {
  return _.get(state, "server.loadingInstancesError", null);
}

export function getLicenses(state) {
  return _.get(state, "server.licenses", []);
}

export function isLoadingLicenses(state) {
  return _.get(state, "server.loadingLicenses", false);
}

export function loadingLicensesError(state) {
  return _.get(state, "server.licensesError", false);
}

export function trialLicenseAvailable(state) {
  return _.get(state, "server.licenseTrialAvailable", false);
}

export function activatingTrial(state) {
  return _.get(state, "server.activatingTrial", false);
}

export function activatingTrialError(state) {
  return _.get(state, "server.activatingTrialError", null);
}

export function isLoadingVersions(state) {
  return _.get(state, "server.loadingVersions", false);
}

export function loadedVersions(state) {
  return _.get(state, "server.loadedVersions", false);
}

export function loadingVersionsError(state) {
  return _.get(state, "server.loadingVersionsError", null);
}

export function getVersions(state) {
  return _.get(state, "server.versions", {});
}
